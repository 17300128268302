var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(_vm.loading)?[_c('common-loading')]:[_c('div',{staticClass:"navheader borderbox",staticStyle:{"border-bottom":"none"}},[_c('img',{staticClass:"back",attrs:{"src":require("../../../assets/img/back-b.png")},on:{"click":function($event){return _vm.goBack()}}}),_c('div',{staticClass:"title"},[_vm._v("收藏题练习")]),_c('div',{staticClass:"sum"},[_vm._v(_vm._s(_vm.activeQid)+"/"+_vm._s(_vm.qidArr.length))])]),_c('div',{staticClass:"time"}),[_c('div',[(_vm.question.type === 1)?_c('question-choice',{attrs:{"num":_vm.activeQid,"wrongBook":true,"question":_vm.question,"is-correct":false,"score":_vm.question.score,"is-over":_vm.showAnswer,"reply":null},on:{"update":_vm.questionUpdate}}):(_vm.question.type === 2)?_c('question-select',{attrs:{"num":_vm.activeQid,"wrongBook":true,"question":_vm.question,"is-correct":false,"score":_vm.question.score,"is-over":_vm.showAnswer,"reply":''},on:{"update":_vm.questionUpdate}}):(_vm.question.type === 3)?_c('question-input',{attrs:{"num":_vm.activeQid,"wrongBook":true,"question":_vm.question,"is-correct":false,"score":_vm.question.score,"is-over":_vm.showAnswer,"reply":''},on:{"update":_vm.questionUpdate}}):(_vm.question.type === 4)?_c('question-qa',{attrs:{"num":_vm.activeQid,"wrongBook":true,"question":_vm.question,"is-correct":false,"score":_vm.question.score,"is-over":_vm.showAnswer,"reply":null},on:{"update":_vm.questionUpdate}}):(_vm.question.type === 5)?_c('question-judge',{attrs:{"num":_vm.activeQid,"wrongBook":true,"question":_vm.question,"is-correct":false,"score":_vm.question.score,"is-over":_vm.showAnswer,"reply":null},on:{"update":_vm.questionUpdate}}):(_vm.question.type === 6)?_c('question-cap',{attrs:{"num":_vm.activeQid,"wrongBook":true,"question":_vm.question,"is-correct":false,"score":_vm.question.score,"show-image":false,"is-over":_vm.showAnswer},on:{"update":_vm.questionUpdate}}):_vm._e()],1),(
                _vm.question &&
                (_vm.question.type === 2 ||
                    _vm.question.type === 3 ||
                    _vm.question.type === 4 ||
                    _vm.question.type === 6)
            )?_c('div',{staticClass:"config-btn"},[_c('div',{staticClass:"see-button",on:{"click":function($event){return _vm.seeAnswer()}}},[_vm._v(_vm._s(_vm.showText))])]):_vm._e()],_c('div',{staticClass:"navfooter borderbox"},[_c('div',{staticClass:"item-footer",on:{"click":function($event){return _vm.deleteAnswer()}}},[_c('img',{attrs:{"src":require("../../../assets/img/star-selected.png"),"alt":""}}),_c('span',[_vm._v("已收藏")])]),_c('div',{staticClass:"item-footer",on:{"click":function($event){return _vm.showCard()}}},[_c('img',{attrs:{"src":require("../../../assets/img/card.png"),"alt":""}}),_c('span',[_vm._v("答题卡")])]),_c('div',{staticClass:"item-footer",on:{"click":function($event){return _vm.prex()}}},[_c('img',{attrs:{"src":require("../../../assets/img/back.png"),"alt":""}}),_c('span',[_vm._v("上一题")])]),_c('div',{staticClass:"item-footer",on:{"click":function($event){return _vm.next()}}},[_c('img',{attrs:{"src":require("../../../assets/img/next.png"),"alt":""}}),_c('span',[_vm._v("下一题")])]),(_vm.show_card)?_c('div',{staticClass:"mask"},[_c('div',{staticClass:"answersheet-item"},[_c('div',{staticClass:"top-box borderbox"},[_c('div',{staticClass:"back",on:{"click":function($event){return _vm.showCard()}}},[_c('van-icon',{attrs:{"name":"close","size":"28px"}})],1)]),_c('div',{staticClass:"answers borderbox active"},[(_vm.qidArr.length > 0)?_c('div',{staticClass:"answer-item borderbox"},[_c('div',{staticClass:"numberbox"},_vm._l((_vm.qidArr),function(qid,qidIndex){return _c('div',{key:qidIndex,staticClass:"num",class:{active: qidIndex + 1 === _vm.activeQid},on:{"click":function($event){return _vm.changeQid(qidIndex+1)}}},[_vm._v(_vm._s(qidIndex + 1))])}),0)]):_vm._e()])])]):_vm._e()])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }